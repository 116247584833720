export const thousandSeperator = (str) => {
    return parseInt(str).toLocaleString('en-US')
}

//returns true if date string provided is in yyyy-mm-dd format else false
export const isValidDate = (dateString) => {
    let date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return false;
    }
    let dateFormat = date.toISOString().slice(0, 10);
    return dateString === dateFormat;
}

// returns date in DD/MM/YYYY format
export const formatDate = (value) => {
    let date = new Date(value).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: "numeric" });
    let year = date.substring(6, 10),
        month = date.substring(0, 2),
        day = date.substring(3, 5)

    return `${day}-${month}-${year}`
}

// returns date in YYYY-MM-DD format
export const formatDate2 = (value) => {
    console.log(value);
    let date = new Date(value);
    let year = date.getFullYear(),
        month = (date.getUTCMonth() + 1),
        day = date.getUTCDate(),
        hour = date.getHours(),
        min = date.getMinutes();
    let period = "AM";
    if (Number(hour) >= 12) {
        hour -= 12;
        period = "PM";
    }
    if (String(month).length === 1) {
        month = "0" + month;
    }
    if (String(day).length === 1) {
        day = "0" + day;
    }
    if (String(hour).length === 1) {
        hour = "0" + hour;
    }
    if (String(min).length === 1) {
        min = "0" + min;
    }

    return `${year}-${month}-${day}`
}


// returns date in DD/MM/YYYY format
export const formatDateTime = (value) => {
    let date = new Date(String(value));
    let year = date.getFullYear(),
        month = (date.getUTCMonth() + 1),
        day = date.getUTCDate(),
        hour = String(value).substring(String(value).length - 13, String(value).length - 11),
        min = String(value).substring(String(value).length - 10, String(value).length - 8);
    let period = "AM";
    if (Number(hour) >= 12) {
        hour -= 12;
        period = "PM";
    }
    if (String(month).length === 1) {
        month = "0" + month;
    }
    if (String(day).length === 1) {
        day = "0" + day;
    }
    if (String(hour).length === 1) {
        hour = "0" + hour;
    }
    if (String(min).length === 1) {
        min = "0" + min;
    }

    return `${day}-${month}-${year} ${hour}:${min}`
}