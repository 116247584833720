import { Backdrop, Button, CssBaseline, Fade, Grid, Modal, Tooltip, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { AdminPage, DataTable } from '../components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { thousandSeperator } from '../utils/Format';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { blue, green, grey } from '@mui/material/colors'
import axios from 'axios'
import { getUser } from "../utils/AuthSession"
import HandoverForm from '../components/HandoverForm'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import logo from '../images/warnes-black.png'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
var htmlToPdfmake = require("html-to-pdfmake");

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const SelectWinners = () => {
    const { carId } = useParams()

    const [paymentMethods, setPaymentMethods] = useState([])
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({})
    const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true)

    const [bids, setBids] = useState([]);
    const [selectedBidId, setSelectedBidId] = useState('')
    const [selectedBid, setSelectedBid] = useState('');
    const [refresh, setRefresh] = useState(false)
    const [previewingLetter, setPreviewingLetter] = useState(false)
    const [instructions, setInstructions] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [paymentMode, setPaymentMode] = useState('')
    const [generatingLetter, setGeneratingLetter] = useState(false)
    const [carDetails, setCarDetails] = useState([])

    const componentRef = React.useRef(null);
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const myRef = useRef(null)

    const navigate = useNavigate()


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setPreviewingLetter(false); setInstructions('') };


    const AcceptBid = async () => {
        setGeneratingLetter(true)
        const element = document.getElementById('award-canvas-final')
        console.log(element.outerHTML);

        const user = getUser()

        axios.post(`${process.env.REACT_APP_API_URL}/admins/acceptBid/${selectedBid.id}`,
            {
                user_id: user.id,
                // pdfBase64: dataUrl,
                dealer_email: selectedBid.email,
                instructions,
                paymentMethod: selectedPaymentMethod.payment_method,
                carId,
                html: element.outerHTML
            }
        )
            .then(res => {
                if (res.data) {
                    handleClose()
                    setRefresh(!refresh)
                }
                setGeneratingLetter(false)
            })
            .catch(err => {
                console.log(err);
                alert(err.response.data)
                setGeneratingLetter(false)
            })

    }

    // const AcceptBid = async () => {
    //     setGeneratingLetter(true)

    //     const element = document.getElementById('award-canvas')
    //     console.log(element);

    //     html2canvas(element).then((canvas) => {
    //         console.log(canvas.toDataURL());
    //         const pdfDocGenerator = pdfMake.createPdf({
    //             pageSize: {width:1792, height:'auto'},
    //             content: [
    //                 {
    //                     image: canvas.toDataURL(),
    //                     // width: element.clientWidth / 2
    //                 }
    //             ],
    //             defaultStyle: {
    //                 alignment: 'center'
    //             }
    //         });
    //         pdfDocGenerator.getDataUrl((dataUrl) => {
    //             // dataUrl contains the base64 URL of the PDF
    //             console.log(dataUrl);

    //             const user = getUser()

    //             axios.post(`${process.env.REACT_APP_API_URL}/admins/acceptBid/${selectedBid.id}`,
    //                 {
    //                     user_id: user.id,
    //                     pdfBase64: dataUrl,
    //                     dealer_email: selectedBid.email,
    //                     instructions,
    //                     carId
    //                 }
    //             )
    //                 .then(res => {
    //                     if (res.data) {
    //                         handleClose()
    //                         setRefresh(!refresh)
    //                     }
    //                     setGeneratingLetter(false)
    //                 })
    //                 .catch(err => {
    //                     console.log(err);
    //                     alert(err.response.data)
    //                     setGeneratingLetter(false)
    //                 })

    //         });
    //     });



    // }
    // const AcceptBid = async () => {
    //     setGeneratingLetter(true)
    //     const doc = new jsPDF('p', 'px', 'a1', true)

    //     const targetElement = document.getElementsByClassName('award-canvas')[0].innerHTML

    //     await doc.html(targetElement, {
    //         callback: function (doc) {

    //         },
    //         x: 180,
    //         y: 100
    //     });

    //     const pdfBase64 = doc.output("datauristring", {filename:`Award Letter ${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().length === 1 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}/${selectedBid.car_id}`})
    //     console.log(pdfBase64);

    //     const user = getUser()

    //     axios.post(`${process.env.REACT_APP_API_URL}/admins/acceptBid/${selectedBid.id}`,
    //         { user_id: user.id, 
    //             pdfBase64: pdfBase64, 
    //             // pdfBase642:pdfBase642, 
    //             // pdfBase643:pdfBase643, 
    //             // pdfBase644:pdfBase644, 
    //             dealer_email: selectedBid.email, instructions, carId }
    //     )
    //         .then(res => {
    //             if (res.data) {
    //                 handleClose()
    //                 setRefresh(!refresh)
    //             }
    //             setGeneratingLetter(false)
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             alert(err.response.data)
    //             setGeneratingLetter(false)
    //         })

    // }
    // const AcceptBid = async () => {
    //     // setGeneratingLetter(true)
    //     const targetElement = document.getElementById('award-canvas')
    //     // const imgData = await htmlToImage.toJpeg(targetElement)
    //     // console.log(imgData);
    //     // const pdf = new jsPDF()
    //     // pdf.addImage(imgData, 'JPEG', 0, 0, 35080, 2480)
    //     // pdf.save('AwardLetter.pdf')
    //     // setGeneratingLetter(false)

    //     html2canvas(targetElement)
    //         .then(async (canvas) => {
    //             console.log(targetElement)
    //             const img = canvas.toDataURL('image/jpeg')
    //             const pdf = new jsPDF()

    //             // calculate the width and height of the image
    //             const imgWidth = 1792;
    //             const imgHeight = 3082;

    //             // calculate the width and height of the PDF page
    //             const pageWidth = pdf.internal.pageSize.getWidth();
    //             const pageHeight = pdf.internal.pageSize.getHeight();

    //             // calculate the scale factor
    //             const scaleFactor = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

    //             console.log(pageWidth, ' - ', pageHeight);
    //             console.log((pageWidth - 172.6) / 2, ' - ', (pageHeight - 297) / 2);

    //             pdf.addImage(img, 'JPEG', 18.7, 0, 172.6, 297)

    //             var out = pdf.output()
    //             var pdfBase64 = 'data:application/pdf;base64,' + btoa(out);

    //             console.log(pdfBase64);

    //             const user = getUser()

    //             axios.post(`${process.env.REACT_APP_API_URL}/admins/acceptBid/${selectedBid.id}`,
    //                 { user_id: user.id, pdfBase64: pdfBase64, dealer_email: selectedBid.email, instructions, carId }
    //             )
    //                 .then(res => {
    //                     if (res.data) {
    //                         handleClose()
    //                         setRefresh(!refresh)
    //                     }
    //                     setGeneratingLetter(false)
    //                 })
    //                 .catch(err => {
    //                     console.log(err);
    //                     alert(err.response.data)
    //                     setGeneratingLetter(false)
    //                 })

    //         })
    //         .catch(err => {
    //             console.log(err)
    //             alert('Could not send award letter to dealer please try accepting bid later.')
    //             setGeneratingLetter(false)
    //         })

    // }

    const handleTextareaHeight = e => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    const showTooltip = (params) => {
        if (params.row[params.field]) {
            return (
                <Tooltip title={params.row[params.field]} placement="top">
                    <label>
                        {params.row[params.field]}
                    </label>
                </Tooltip>
            );
        }
    }
    const renderCell = { renderCell: showTooltip };

    const columns = [
        {
            field: 'id', headerName: 'ID',
            renderCell: (params) => {
                return params.row['id'];
            }
        },
        {
            field: 'accepted', headerName: 'Select Winner', minWidth: 120,
            renderCell: (params) => {
                // if (new Date(params.row.session_end) > new Date()) {
                //     return <Tooltip title="Can't select winner of ongoing session">
                //         <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                //             -
                //         </Box>
                //     </Tooltip>
                // } else {
                if (params.row.accepted === 0) {
                    return <Tooltip title="Select Winners" placement="right">
                        <Button onClick={() => {
                            setSelectedBid(params.row)
                            setPreviewingLetter(true)
                            handleOpen()
                        }}>
                            <EmojiEventsIcon sx={{ color: "rgba(0, 0, 0, 0.7)" }} />
                        </Button>
                    </Tooltip>
                }
                else if (params.row.accepted === 1) {
                    return <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                        <Tooltip title='View Letter'>
                            <VisibilityIcon
                                sx={{ fontSize: '27px', color: grey[800], cursor: 'pointer' }}
                                onClick={() => {
                                    setSelectedBid(params.row)
                                    setInstructions(params.row.instructions)
                                    setPaymentMethod(params.row.payment_method)
                                    setPreviewingLetter(true)
                                    handleOpen()
                                }}
                            />
                        </Tooltip>
                        <Tooltip title='Print Handover Form'>
                            <LocalPrintshopIcon
                                sx={{ cursor: 'pointer', color: "rgba(0, 0, 0, 0.7)" }}
                                onClick={() => {
                                    setSelectedBid(params.row)
                                    handleOpen()
                                }}
                            />
                        </Tooltip>
                    </Box>
                }
                else if (params.row.accepted === 2) {
                    return <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                        -
                    </Box>
                }
                // }
            }
        },
        // { field: 'car_id', headerName: 'car_id', },
        // { field: 'bidsession_id', headerName: 'bidsession_id', },
        { field: 'dealer', headerName: 'Dealer', },
        { field: 'company_name', headerName: 'Company Name', },
        // { field: 'model', headerName: 'model', },
        // { field: 'car_reg', headerName: 'regno', },
        {
            field: 'bid_amount', headerName: 'bid Amount',
            renderCell: params => {
                return `$${thousandSeperator(params.row.bid_amount)}`
            }
        },
        { field: 'car_or_body', headerName: 'Bid Type', },
        {
            field: 'GP', headerName: 'Gross Profit',
            renderCell: (params) => {
                return `
                ${params.row.GP < 0
                        ? `-$${thousandSeperator(params.row.GP * -1)}`
                        : `$${thousandSeperator(params.row.GP)}`
                    }
                `
            }
        },
        // { field: 'colour', headerName: 'colour', },
        // { field: 'yom', headerName: 'yom', },
        // { field: 'regn_date', headerName: 'regn_date', },
        // { field: 'mileage', headerName: 'mileage', },
        // { field: 'omv', headerName: 'omv', },
        // { field: 'owners', headerName: 'owners', },
        // { field: 'papervalue', headerName: 'papervalue', },
        // { field: 'minbidvalue_wholecar', headerName: 'minbidvalue_wholecar', },
        // { field: 'minbidvalue_bodyonly', headerName: 'minbidvalue_bodyonly', },
        // { field: 'roadtax_expiry', headerName: 'roadtax_expiry', },
        // { field: 'remarks', headerName: 'remarks', },
        {
            field: 'when_bidded', headerName: 'when_bidded', minWidth: '150',
            renderCell: params => {
                return new Date(params.row.when_bidded).toLocaleString('en-US')
            }
        },
        { field: 'bid_status', headerName: 'Bid Status', },
    ];

    useEffect(() => {
        getCarDetails()
        getBids();
    }, [carId, refresh])

    const getBids = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/bids/carbids/` + carId)
            .then(res => {
                if (res.data && typeof res.data !== 'string') {
                    setBids(res.data)
                    console.log(res.data);
                    res.data.forEach(bid => {
                        if (bid.accepted === 1) {
                            setInstructions(bid.instructions)
                        }
                    })
                } else {
                    alert(res.data);
                }
            })
            .catch(err => {
                console.log(err.response.data);
            });
    }

    const getCarDetails = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/cars/car/` + carId)
            .then(res => {
                setCarDetails(res.data)
            })
            .catch(err => {
                alert(err.response.data);
            });
    }


    const formatDate = (value) => {
        //DD/MM/YYYY hh:mm a
        let date = new Date(String(value));
        let year = date.getFullYear(),
            month = (date.getUTCMonth() + 1),
            day = date.getUTCDate(),
            hour = date.getHours(),
            min = date.getMinutes();
        let period = "AM";
        if (Number(hour) >= 12) {
            hour -= 12;
            period = "PM";
        }
        if (String(month).length === 1) {
            month = "0" + month;
        }
        if (String(day).length === 1) {
            day = "0" + day;
        }
        if (String(hour).length === 1) {
            hour = "0" + hour;
        }
        if (String(min).length === 1) {
            min = "0" + min;
        }
        return (
            <>
                {`${day}-${month}-${year} ${hour}:${min}`}
            </>
        );
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        p: 4,
    }


    useEffect(() => {
        getPaymentMethods()
    }, [])

    const getPaymentMethods = () => {
        setLoadingPaymentMethods(true)
        axios.get(`${process.env.REACT_APP_API_URL}/admins/paymentMethods/all`)
            .then(res => {
                if (res.data) {
                    setPaymentMethods(res.data)
                    setSelectedPaymentMethod(res.data[0])
                }
                setLoadingPaymentMethods(false)
            }).catch(error => {
                alert("Could not get payment methods at the moment!")
                console.log(error);
                setLoadingPaymentMethods(false)
            });
    }


    return (

        <AdminPage>
            <Container maxWidth="lg">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    <div className="flex ">
                        <button className="back-btn" onClick={() => navigate(-1)}>
                            <ArrowBackIcon /> Back
                        </button>
                        <Typography component="h1" variant="h5">
                            Select Winner
                        </Typography>
                    </div>

                    <Container maxWidth="lg" sx={{ mt: '-3.6rem', mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <div className="header-flexed select-winner">
                                        <div />
                                        <div className="header-right-aligned">
                                            {carDetails
                                                &&
                                                <div className='select-winner-header'>
                                                    <div className='top'>
                                                        <h2>{carDetails.model}</h2>
                                                        <span className='seperator'></span>
                                                        <p>{carDetails.car_reg}</p>
                                                    </div>
                                                    <div className='bottom'>
                                                        <p><span>Reserved Price Wholecar</span> - ${thousandSeperator(carDetails.car_cost)}</p>
                                                        <span className='seperator'></span>
                                                        <p><span>Reserved Price Bodyonly</span> - ${thousandSeperator(carDetails.car_cost - (carDetails.papervalue - carDetails.roadtax_rebate))}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <DataTable rows={bids}
                                        // columns={columns.map(column => { return { ...column, ...renderCell } })}
                                        columns={columns}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Container>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className='award-modal'
            >

                <Fade in={open}>


                    {previewingLetter
                        ?
                        //Award Letter
                        <Box sx={style}>
                            <h3>Award Letter Preview</h3>
                            <hr className='hr'></hr>

                            {/* ---Award--- */}
                            <div id='award-preview' className='award-preview'>
                                <div className='award-canvas'>

                                    {/*  THIS IS ONLY FOR PREVIEW */}
                                    <div id='award-canvas' style={{ width: '880px', padding: '0.5rem 2rem', backgroundColor: '#fff' }}>
                                        <div id='award-container' className='award-container' style={{ border: '1px solid #2b2c2e', borderRadius: '10px', padding: '3rem 4rem', margin: '2rem 0', color: 'rgb(26, 25, 25)' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-end' }} className='right'>
                                                <img src={logo} alt='logo' style={{ width: '8rem' }} />
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>{new Date().getFullYear()}-{(new Date().getMonth() + 1).toString().length === 1 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}/{selectedBid.car_id}</p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start', fontWeight: '500' }} className='left'>
                                                <p style={{ margin: 0, marginTop: '-1.7rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>
                                                    WEARNES AUTOMOTIVE PTE LTD <br />
                                                    PRE-OWNED CARS BIDDING RESULT <br />
                                                    AUCTION DATE: {new Date(selectedBid.session_end).toLocaleString('en-US')} <br />
                                                </p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0', marginTop: '1.5rem' }} className='center'>
                                                <div className='data-container' style={{ display: 'flex', margin: '0.5rem 0', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>
                                                    <span className='data-title' style={{ width: '40%', fontWeight: '500', textAlign: 'right' }}>CAR NO.</span>
                                                    <span className='seperator' style={{ width: '20%', fontWeight: '500', textAlign: 'center' }}>:</span>
                                                    <span className='data' style={{ width: '40%', textAlign: 'left' }}>{selectedBid.car_reg}</span>
                                                </div>
                                                <div className='data-container' style={{ display: 'flex', margin: '0.5rem 0', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>
                                                    <span className='data-title' style={{ width: '40%', fontWeight: '500', textAlign: 'right' }}>MODEL</span>
                                                    <span className='seperator' style={{ width: '20%', fontWeight: '500', textAlign: 'center' }}>:</span>
                                                    <span className='data' style={{ width: '40%', textAlign: 'left' }}>{selectedBid.model}</span>
                                                </div>
                                                <div className='data-container' style={{ display: 'flex', margin: '0.5rem 0', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>
                                                    <span className='data-title' style={{ width: '40%', fontWeight: '500', textAlign: 'right' }}>HIGHEST BID</span>
                                                    <span className='seperator' style={{ width: '20%', fontWeight: '500', textAlign: 'center' }}>:</span>
                                                    <span className='data' style={{ width: '40%', textAlign: 'left' }}>${thousandSeperator(selectedBid.bid_amount)} ({selectedBid.car_or_body})</span>
                                                </div>
                                                <div className='data-container' style={{ display: 'flex', margin: '0.5rem 0', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>
                                                    <span className='data-title' style={{ width: '40%', fontWeight: '500', textAlign: 'right' }}>AWARDED TO</span>
                                                    <span className='seperator' style={{ width: '20%', fontWeight: '500', textAlign: 'center' }}>:</span>
                                                    <span className='data' style={{ width: '40%', textAlign: 'left' }}>{selectedBid.company_name}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '.6rem 0', marginTop: '0.5rem' }} className='normal'>
                                                <h3 style={{ fontWeight: '500', fontSize: '1.1rem', marginBottom: '0.4rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>INSTRUCTION:</h3>
                                                <textarea
                                                    style={{ border: '1px solid #2b2c2e', borderRadius: '10px', padding: '2rem', fontSize: '1rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'", overflow: 'hidden', resize: 'none' }}
                                                    autoFocus={selectedBid.accepted ? false : true}
                                                    onKeyDown={handleTextareaHeight} id='instructions' disabled={selectedBid.accepted} className='bullets-container' value={instructions} onChange={e => setInstructions(e.target.value)} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} className='normal'>
                                                <h3 style={{ fontWeight: '500', fontSize: '1.1rem', marginBottom: '0.4rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>REMARKS:</h3>
                                                <div className='bullets-container' style={{ border: '1px solid #2b2c2e', borderRadius: '10px', padding: '2rem', fontSize: '1rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'", overflow: 'hidden', resize: 'none' }}>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>1.</span><span className='text' style={{ width: '95%' }}> Payment is strictly by FAST/ PAYNOW only</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>2.</span><span className='text' style={{ width: '95%' }}> Collection of the car at 45 Leng Kee Road Singapore 159103 after Wearnes Automotive verified payment is received</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>3.</span><span className='text' style={{ width: '95%' }}> For car body sale,  Wearnes Automotive will deregister the car within 7 days upon collection of the car</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>4.</span><span className='text' style={{ width: '95%' }}> For the car body, the dealer is required to submit the necessary documents to us/ LTA/ Scrapyard</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>5.</span><span className='text' style={{ width: '95%' }}> Any traffic/ LTA offences or fines incurred after the handover will be borne by the Dealer</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>6.</span><span className='text' style={{ width: '95%' }}> Do bring along Company Stamp/ M01 form</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>7.</span><span className='text' style={{ width: '95%' }}> Cars are sold in “As Is” condition</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>8.</span><span className='text' style={{ width: '95%' }}> Cars sold are non-refundable/ non-returnable</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>9.</span><span className='text' style={{ width: '95%' }}> ANY CANCELLATION AFTER THE BID IS AWARDED WILL RESULT IN EITHER A 10% PENALTY OF THE SUCCESSFUL BID PRICE OR A MINIMUM CANCELLATION FEE OF $2,000, WHICHEVER IS HIGHER.</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>10.</span><span className='text' style={{ width: '95%' }}> Any additional interest cost incurred as a result of the cancellation will be borne by the dealer</span></span>
                                                </div>
                                            </div>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>Collection of vehicle(s) : Eunice Wong (DID) 6430 4763 , (M) 8468 8813
                                                <br />
                                                This is a computer generated letter so no signatory is required</p>

                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: "2rem", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'", }} className='bank-details'>
                                                {/* <p className='payment-modes'>Payment Modes: </p>
                                                <h4 style={{ fontWeight: '500' }}>{paymentMode}</h4>
                                                <p>Bank Details: UOB Bank</p>
                                                <p style={{}}>Bank Account: 450-302-137-9</p>
                                                <p>Do include Car No & Company name</p>

                                                <textarea
                                                    style={{ border: '1px solid #2b2c2e', borderRadius: '10px', padding: '2rem', fontSize: '1rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'", overflow: 'hidden', resize: 'none', width: "50%" }}
                                                    autoFocus={selectedBid.accepted ? false : true}
                                                    onKeyDown={handleTextareaHeight} id='payment-method' disabled={selectedBid.accepted} className='bullets-container' value={paymentMode} onChange={e => setPaymentMode(e.target.value)} /> */}

                                                {selectedBid.accepted === 0
                                                    ?
                                                    <>
                                                        <p>Select a payment method</p>
                                                        <select
                                                            style={{ height: "2.5rem", borderRadius: "10px", border: "1px solid #2b2c2e", padding: "0 1rem" }}
                                                            value={JSON.stringify(selectedPaymentMethod)}
                                                            onChange={e => setSelectedPaymentMethod(JSON.parse(e.target.value))}
                                                        >
                                                            {paymentMethods.map((method, i) => (
                                                                <option value={JSON.stringify(method)}>{method.method_name}</option>
                                                            ))}
                                                        </select>
                                                    </>

                                                    :
                                                    <>
                                                        <p className='payment-modes' style={{ fontWeight: "600" }}>Payment Modes: </p>
                                                        <p style={{ whiteSpace: "pre", marginTop: "-0.5rem" }}>{paymentMethod}</p>
                                                    </>
                                                }
                                            </div>

                                        </div>
                                    </div>


                                    {/* THIS WILL BE SENT FOR MAKING PDF -- ITS HIDDEN ON FRONTEND */}
                                    <div id='award-canvas-final' style={{ width: '880px', padding: '0.5rem 2rem', backgroundColor: '#fff' }}>
                                        <div id='award-container' className='award-container' style={{ border: '1px solid #2b2c2e', borderRadius: '10px', padding: '3rem 4rem 2rem 4rem', margin: '2rem 0', color: 'rgb(26, 25, 25)' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-end' }} className='right'>
                                                <img src={logo} alt='logo' style={{ width: '8rem' }} />
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>{new Date().getFullYear()}-{(new Date().getMonth() + 1).toString().length === 1 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}/{selectedBid.car_id}</p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start', fontWeight: '500' }} className='left'>
                                                <p style={{ margin: 0, marginTop: '-1.7rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>
                                                    WEARNES AUTOMOTIVE PTE LTD <br />
                                                    PRE-OWNED CARS BIDDING RESULT <br />
                                                    AUCTION DATE: {new Date(selectedBid.session_end).toLocaleString('en-US')} <br />
                                                </p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0', marginTop: '1.5rem' }} className='center'>
                                                <div className='data-container' style={{ display: 'flex', margin: '0.5rem 0', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>
                                                    <span className='data-title' style={{ width: '40%', fontWeight: '500', textAlign: 'right' }}>CAR NO.</span>
                                                    <span className='seperator' style={{ width: '20%', fontWeight: '500', textAlign: 'center' }}>:</span>
                                                    <span className='data' style={{ width: '40%', textAlign: 'left' }}>{selectedBid.car_reg}</span>
                                                </div>
                                                <div className='data-container' style={{ display: 'flex', margin: '0.5rem 0', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>
                                                    <span className='data-title' style={{ width: '40%', fontWeight: '500', textAlign: 'right' }}>MODEL</span>
                                                    <span className='seperator' style={{ width: '20%', fontWeight: '500', textAlign: 'center' }}>:</span>
                                                    <span className='data' style={{ width: '40%', textAlign: 'left' }}>{selectedBid.model}</span>
                                                </div>
                                                <div className='data-container' style={{ display: 'flex', margin: '0.5rem 0', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>
                                                    <span className='data-title' style={{ width: '40%', fontWeight: '500', textAlign: 'right' }}>HIGHEST BID</span>
                                                    <span className='seperator' style={{ width: '20%', fontWeight: '500', textAlign: 'center' }}>:</span>
                                                    <span className='data' style={{ width: '40%', textAlign: 'left' }}>${thousandSeperator(selectedBid.bid_amount)} ({selectedBid.car_or_body})</span>
                                                </div>
                                                <div className='data-container' style={{ display: 'flex', margin: '0.5rem 0', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>
                                                    <span className='data-title' style={{ width: '40%', fontWeight: '500', textAlign: 'right' }}>AWARDED TO</span>
                                                    <span className='seperator' style={{ width: '20%', fontWeight: '500', textAlign: 'center' }}>:</span>
                                                    <span className='data' style={{ width: '40%', textAlign: 'left' }}>{selectedBid.company_name}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '.6rem 0', marginTop: '0.5rem' }} className='normal'>
                                                <h3 style={{ fontWeight: '500', fontSize: '1.1rem', marginBottom: '0.4rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>INSTRUCTION:</h3>
                                                <textarea
                                                    style={{ border: '1px solid #2b2c2e', borderRadius: '10px', padding: '2rem', fontSize: '1rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'", overflow: 'hidden', resize: 'none' }}
                                                    autoFocus={selectedBid.accepted ? false : true}
                                                    onKeyDown={handleTextareaHeight} id='instructions' disabled={selectedBid.accepted} className='bullets-container' value={instructions} onChange={e => setInstructions(e.target.value)} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} className='normal'>
                                                <h3 style={{ fontWeight: '500', fontSize: '1.1rem', marginBottom: '0.4rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>REMARKS:</h3>
                                                <div className='bullets-container' style={{ border: '1px solid #2b2c2e', borderRadius: '10px', padding: '2rem', fontSize: '1rem', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'", overflow: 'hidden', resize: 'none' }}>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>1.</span><span className='text' style={{ width: '95%' }}> Payment is strictly by FAST/ PAYNOW only</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>2.</span><span className='text' style={{ width: '95%' }}> Collection of the car at 45 Leng Kee Road Singapore 159103 after Wearnes Automotive verified payment is received</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>3.</span><span className='text' style={{ width: '95%' }}> For car body sale,  Wearnes Automotive will deregister the car within 7 days upon collection of the car</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>4.</span><span className='text' style={{ width: '95%' }}> For the car body, the dealer is required to submit the necessary documents to us/ LTA/ Scrapyard</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>5.</span><span className='text' style={{ width: '95%' }}> Any traffic/ LTA offences or fines incurred after the handover will be borne by the Dealer</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>6.</span><span className='text' style={{ width: '95%' }}> Do bring along Company Stamp/ M01 form</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>7.</span><span className='text' style={{ width: '95%' }}> Cars are sold in “As Is” condition</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>8.</span><span className='text' style={{ width: '95%' }}> Cars sold are non-refundable/ non-returnable</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>9.</span><span className='text' style={{ width: '95%' }}> ANY CANCELLATION AFTER THE BID IS AWARDED WILL RESULT IN EITHER A 10% PENALTY OF THE SUCCESSFUL BID PRICE OR A MINIMUM CANCELLATION FEE OF $2,000, WHICHEVER IS HIGHER.</span></span>
                                                    <span style={{ display: 'flex' }}><span style={{ width: '5%' }} className='points'>10.</span><span className='text' style={{ width: '95%' }}> Any additional interest cost incurred as a result of the cancellation will be borne by the dealer</span></span>
                                                </div>
                                            </div>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'" }}>Collection of vehicle(s) : Eunice Wong (DID) 6430 4763 , (M) 8468 8813
                                                <br />
                                                This is a computer generated letter so no signatory is required</p>

                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'", marginTop: '-1rem' }} className='bank-details'>
                                                {/* <p className='payment-modes'>Payment Modes: </p>
                                                <h4 style={{ fontWeight: '500', margin: '-1rem 0', }}>By FAST/Pay Now</h4>
                                                <p>Bank Details: UOB Bank</p>
                                                <p style={{ margin: '-1rem 0' }}>Bank Account: 450-302-137-9</p>
                                                <p>Do include Car No & Company name</p> */}

                                                <p className='payment-modes' style={{ fontWeight: "600" }}>Payment Modes: </p>
                                                <p style={{ whiteSpace: "pre", marginTop: "-1rem" }}>{selectedPaymentMethod?.payment_method.trim()}</p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <br />
                            <hr className='hr'></hr>
                            {selectedBid.accepted === 0
                                &&
                                <button disabled={generatingLetter} className='btn light-btn btn-pill' onClick={() => AcceptBid()}>{generatingLetter ? 'Sending...' : 'Accept Bid & Send Letter'}</button>
                            }
                        </Box>

                        :
                        //Handover Form
                        <Box sx={style}>
                            <h3>Handover Form Preview</h3>
                            <hr className='hr'></hr>

                            <div className='letter-preview'>
                                <HandoverForm innerRef={componentRef} details={selectedBid} />
                            </div>
                            <br />
                            <hr className='hr'></hr>
                            {/* <button className='btn light-btn btn-pill' onClick={() => HandlePrint()}>Print</button> */}
                            <ReactToPrint
                                trigger={() => <button className='btn light-btn btn-pill'>Print</button>}
                                content={reactToPrintContent}
                            />
                        </Box>
                    }

                </Fade>
            </Modal>
        </AdminPage>
    );
}

export default SelectWinners