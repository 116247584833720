import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import common from "@mui/material/colors/common";
import red from "@mui/material/colors/red";
import green from "@mui/material/colors/green";
import yellow from "@mui/material/colors/yellow";
import { DataTable, TabPanel, CreateNewBidSessionForm, UploadFileForm, AdminPage } from "../components";
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import CancellationRequests from "../components/CancellationRequests";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { RiAuctionFill } from 'react-icons/ri'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { GridToolbar } from "@mui/x-data-grid";
import MonthlyReport from "../components/MonthlyReport";
import axios from "axios";
import { getUser, isFinance } from "../utils/AuthSession";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { RiMailSendFill } from 'react-icons/ri'
import { Parser } from 'json2csv'
import { FaFileExport } from 'react-icons/fa'
import { formatDate, thousandSeperator } from "../utils/Format";

export default function BidManagementPage() {
    return (
        <AdminPage>
            <Container maxWidth="lg">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Bid Management
                    </Typography>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    {isFinance()
                                        ? <BidManagementTabs />

                                        : <BidSessionsAdmin />
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Container>
        </AdminPage>
    )
}

function BidManagementTabs() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box className='tab-container' sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs className='tabs' value={value} onChange={handleChange} centered>
                <Tab className='tab' label="Bid Sessions" />
                <Tab className='tab' label="New Session" />
                {/* <Tab label="Cancellation Requests" /> */}
            </Tabs>

            <TabPanel value={value} index={0}>
                <BidSessionsAdmin />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CreateNewBidSessionForm />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
                <CancellationRequests/>
            </TabPanel> */}
        </Box>
    );
}

function BidSessionsAdmin() {
    const [sessions, setSessions] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [refresh, setRefresh] = useState(false)


    let columns = [
        {
            field: 'id', headerName: 'ID',
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'session_no', headerName: 'Session',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'session_start', headerName: 'Start',
            renderCell: (params) => {
                return <Tooltip title={
                    new Date(params.row.session_start).toLocaleString('en-US')
                }
                >
                    <p sx={{ padding: '0 2rem' }}>
                        {
                            new Date(params.row.session_start).toLocaleString('en-US')
                        }
                    </p>
                </Tooltip>
            },
            headerAlign: 'center',
            minWidth: 170,
        },
        {
            field: 'session_end', headerName: 'End', type: 'string',
            renderCell: (params) => {
                return <Tooltip title={
                    new Date(params.row.session_end).toLocaleString('en-US')
                }
                >
                    <p sx={{ padding: '0 2rem' }}>
                        {
                            new Date(params.row.session_end).toLocaleString('en-US')
                        }
                    </p>
                </Tooltip>
            },
            headerAlign: 'center',
            minWidth: 170,
        },
        {
            field: 'session_status', headerName: 'Status', sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const changeStatus = (row) => {
                    const { id, session_status, session_end } = row;
                    let change = session_status ? "Deactivate" : "Activate";
                    if (new Date(session_end) < new Date()) {
                        alert('Cannot change status, session passed end date.')
                    } else {
                        if (window.confirm(`${change} Bid Session Status?`)) {
                            axios.post(`${process.env.REACT_APP_API_URL}/bidSessions/updatebidsessionstatus`,
                                {
                                    session_status: !session_status,
                                    session_id: id,
                                    user_id: getUser().id
                                }
                            )
                                .then(res => {
                                    alert(res.data);
                                    getBidSessions();
                                }).catch(error => {
                                    console.log(error.response.data);
                                });
                        }
                    }

                }
                return (
                    <IconButton onClick={(e) => {
                        e.preventDefault();
                        changeStatus(params.row);
                    }}>
                        {params.row.session_status
                            ? <CheckBoxIcon sx={{ color: green[500], fontSize: '27px' }} />
                            : <DisabledByDefaultIcon sx={{ color: red[500], fontSize: '27px' }} />}
                    </IconButton>
                );
            },
            maxWidth: 80,
        },
        {
            field: 'cars', headerName: 'Cars',
            headerAlign: 'center',
            align: 'center',
            maxWidth: 60,
        },
        {
            field: 'total_session_bids', headerName: 'Bids',
            headerAlign: 'center',
            align: 'center',
            maxWidth: 60,
        },
        {
            field: 'actions', headerName: 'Actions', headerAlign: 'center', align: 'center', sortable: false, flex: 1, minWidth: 250,
            renderCell: (params) => {
                return (<BidSessionActions row={params.row} refresh={() => setRefresh(!refresh)} />)
            }
        },
    ]

    if (isFinance()) {
        columns = [
            {
                field: 'id', headerName: 'ID',
                headerAlign: 'center',
                align: 'center',
            },
            {
                field: 'session_no', headerName: 'Session',
                headerAlign: 'center',
                align: 'center'
            },
            {
                field: 'session_start', headerName: 'Start',
                renderCell: (params) => {
                    return <Tooltip title={
                        new Date(params.row.session_start).toLocaleString('en-US')
                    }
                    >
                        <p sx={{ padding: '0 2rem' }}>
                            {
                                new Date(params.row.session_start).toLocaleString('en-US')
                            }
                        </p>
                    </Tooltip>
                },
                headerAlign: 'center',
                minWidth: 170,
            },
            {
                field: 'session_end', headerName: 'End', type: 'string',
                renderCell: (params) => {
                    return <Tooltip title={
                        new Date(params.row.session_end).toLocaleString('en-US')
                    }
                    >
                        <p sx={{ padding: '0 2rem' }}>
                            {
                                new Date(params.row.session_end).toLocaleString('en-US')
                            }
                        </p>
                    </Tooltip>
                },
                headerAlign: 'center',
                minWidth: 170,
            },
            {
                field: 'cars', headerName: 'Cars',
                headerAlign: 'center',
                align: 'center',
                maxWidth: 60,
            },
            {
                field: 'total_session_bids', headerName: 'Bids',
                headerAlign: 'center',
                align: 'center',
                maxWidth: 60,
            },
            {
                field: 'actions', headerName: 'Actions', headerAlign: 'center', align: 'center', sortable: false, flex: 1, minWidth: 280,
                renderCell: (params) => {
                    return (<BidSessionActions row={params.row} refresh={() => setRefresh(!refresh)} />)
                }
            },
        ]
    }


    const getBidSessions = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/bidSessions`,
            {
                selectedMonth: selectedMonth,
                selectedYear: selectedYear
            }
        ).then(res => {
            if (res.data && res.data.length > 0 && typeof res.data !== 'string') {
                setSessions(res.data)
            }
        }).catch(error => {
            alert(error.response.data)
        });
    }

    useEffect(() => {
        console.log(`filtering for month ${selectedMonth}`);
        getBidSessions();
        return () => setSessions([]);
    }, [selectedMonth, selectedYear, refresh]);

    const exportMonthlyReport = () => {
        console.log(`exportting data for month-${selectedMonth} and year-${selectedYear}`)
        const user = getUser()
        axios.post(`${process.env.REACT_APP_API_URL}/admins/bidsInMonth`, { selectedMonth, selectedYear, username: user.username, user_id: user.id })
            .then(res => {
                console.log(res.data);

                //create csv file of the data
                const fileName = `MonthlyReport ${['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][selectedMonth - 1]} ${selectedYear}`
                let fields = ['ID', 'Username', 'Company Name', 'Email', 'Model', 'Car Registration No', 'Bid Amount', 'Bid Type', 'Bid Date Time', 'Bid Status', 'Mileage', 'OMV', 'Roadtax Rebate', 'Owners', 'Car Cost', 'Body Only Reserve Price', 'Whole Body Reserve Price',];
                const opts = { fields };

                const parser = new Parser(opts);
                const csv = parser.parse(res.data);

                downloadFile({
                    data: csv,
                    fileName: fileName,
                    fileType: 'text/csv'
                })

                //add audit trail in db
                let role = `${isFinance() ? 'finance' : 'admin'}`
                axios.post(`${process.env.REACT_APP_API_URL}/audits/add/exportedMonthlyReport`, { selectedMonth, selectedYear, user_id: user.id, role })

            })
            .catch(err => {
                alert(err.response.data)
            })
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }


    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 2022; i <= currentYear; i++) {
            years.push(
                <MenuItem key={i} value={i}>
                    {i}
                </MenuItem>
            );
        }
        return years;
    };


    return (<>
        <div className="header-flexed">
            {/* <a className="link" href='https://wearnes-bidding.smmile.com/files/sample_car_details.xlsx'>Sample CSV</a> */}
            {isFinance()
                // ?
                &&
                <a
                    className="btn light-btn-ghost btn-pill"
                    style={{ fontSize: '14px', position: 'inherit', marginBottom: '2rem' }}
                    href='https://wearnes-bidding.smmile.com/files/sample_car_details.xlsx'
                >
                    Sample CSV
                </a>

                // :<div/>
            }

            {/* test */}
            {!isFinance()
                &&
                <button
                    className="btn light-btn-ghost btn-pill"
                    style={{ fontSize: '14px', position: 'inherit', marginBottom: '2rem' }}
                    onClick={() => {
                        axios.post(`${process.env.REACT_APP_API_URL}/sendExpiredSessionReports`)
                            .then(res => {
                                console.log(new Date(res.data[0]['Bid Date Time']).toLocaleString({ timeZone: 'Aisa/Singapore' }));
                                alert(res.data)
                            })
                    }}
                >
                    Test - Send Reports for Expired Session
                </button>
            }


            <div className="header-right-aligned">
                <FormControl sx={{ width: '120px' }}>
                    <InputLabel id="simple-select-label">Select Year</InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        value={selectedYear}
                        label="Select Month"
                        onChange={(e) => { setSelectedYear(e.target.value) }}
                    >
                        {generateYearOptions()}
                    </Select>
                </FormControl>

                <FormControl sx={{ width: '150px' }}>
                    <InputLabel id="simple-select-label">Select Month</InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        value={selectedMonth}
                        label="Select Month"
                        onChange={(e) => { setSelectedMonth(e.target.value) }}
                    >
                        <MenuItem value=''>Select All</MenuItem>
                        <MenuItem value={1}>January</MenuItem>
                        <MenuItem value={2}>February</MenuItem>
                        <MenuItem value={3}>March</MenuItem>
                        <MenuItem value={4}>April</MenuItem>
                        <MenuItem value={5}>May</MenuItem>
                        <MenuItem value={6}>June</MenuItem>
                        <MenuItem value={7}>July</MenuItem>
                        <MenuItem value={8}>August</MenuItem>
                        <MenuItem value={9}>September</MenuItem>
                        <MenuItem value={10}>October</MenuItem>
                        <MenuItem value={11}>November</MenuItem>
                        <MenuItem value={12}>December</MenuItem>
                    </Select>
                </FormControl>

                <Tooltip
                    title="Export all bids in all sessions of selected month"
                    placement="top"
                >
                    <button className="btn light-btn btn-pill" disabled={(selectedMonth === '' || sessions.length === 0) ? true : false} onClick={() => exportMonthlyReport()}>Export Monthly Report</button>
                </Tooltip>
            </div>
        </div>

        <DataTable rows={sessions} columns={columns} rowHeight={50} pageSize={10} style={{ height: '550px' }} getRowHeight={() => 'auto'} />
    </>);

    function displayDateTime(value) {
        //DD/MM/YYYY hh:mm a
        let date = new Date(String(value));
        let year = date.getFullYear(),
            month = (date.getUTCMonth() + 1),
            day = date.getUTCDate(),
            hour = date.getHours(),
            min = date.getMinutes();
        let period = "AM";
        if (Number(hour) >= 12) {
            hour -= 12;
            period = "PM";
        }
        if (String(month).length === 1) {
            month = "0" + month;
        }
        if (String(day).length === 1) {
            day = "0" + day;
        }
        if (String(hour).length === 1) {
            hour = "0" + hour;
        }
        if (String(min).length === 1) {
            min = "0" + min;
        }
        // let new_date = day + "/" + month + "/" + year + " " + hour + ":" + min + " " + period;
        // return new_date;
        return (
            <Container>
                <Grid container justifyContent="center" >
                    {day + "/" + month + "/" + year}
                </Grid>
                <Grid container justifyContent="center" >
                    {hour + ":" + min + " " + period}
                </Grid>
            </Container>
        );
    }
};

function BidSessionActions(props) {
    const navigate = useNavigate();
    const { row, refresh } = props;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [emailContent, setEmailContent] = useState(`
Dear Dealers,
    
Please see attached for the Bidding List for this week and do note that you are advised to do your due diligence on car condition, mileage and other information as we do not warrant the accuracy of this info.

Please note that No 5 & 6 is not available for viewing. Basically, it is our management fleet and at their discretion to award to highest bidder regardless of colour code. Conversion fee will be at bidder cost.

“In accordance with Singapore Budget 2022, the GST rate will be increased from 7% to 8% with effect from 01 January 2023.
    With these changes, please be informed that any payment made from 1 Jan 2023 will be subjected to 8% GST” `)
    const [carsDetails, setCarsDetails] = useState([])

    const [sendingEmail, setSendingEmail] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [openCarListEmailModal, setOpenCarListEmailModal] = useState(false)
    const openCarListModal = () => {
        fetchCarsInSession()
        setOpenCarListEmailModal(true)
        setSendingEmail(false)
        setEmailSent(false)

    }
    const closeCarListModal = () => {
        setOpenCarListEmailModal(false)
        setSendingEmail(false)
        setEmailSent(false)

        refresh()
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const viewSession = (session) => {
        // params.row.session_no
        navigate(`/viewcars/${session.session_no}`);
    };
    const uploadEvent = (e) => {
        e.stopPropagation();
        handleOpen();
    };
    const viewEvent = (e) => {
        e.stopPropagation();
        viewSession(row);
    }
    const emailCarListEvent = () => {
        setSendingEmail(true)
        axios.post(`${process.env.REACT_APP_API_URL}/admins/sendCarListEmail`,
            {
                session_id: row.id,
                username: getUser().username,
                user_id: getUser().id,
                content: emailContent
            }
        )
            .then(res => {
                setSendingEmail(false)
                setEmailSent(true)

            })
            .catch(err => {
                setSendingEmail(false)
                alert(err.response.data)
            })
    }

    const fetchCarsInSession = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/admins/cars/${row.id}`,
            {
                username: getUser().username,
                user_id: getUser().id
            }
        )
            .then(res => {
                console.log('car details - ', res.data);
                setCarsDetails(res.data)
            })
            .catch(err => {
                console.log(err.response.data)
            })
    }

    const exportSessionReport = (sessionNo) => {
        const user = getUser()
        axios.post(`${process.env.REACT_APP_API_URL}/admins/allBids/${sessionNo}`, { user_id: user.id })
            .then(res => {
                console.log(res.data);

                //create csv file of the data
                const fileName = `Session ${sessionNo} Report`
                let fields = ['id', 'Username', 'Company Name', 'Email', 'Model', 'Regn No', 'Amount', 'Bid Type', 'Bid Date-Time', 'Accepted', 'Bid Status'];
                const opts = { fields };

                const parser = new Parser(opts);
                const csv = parser.parse(res.data);

                downloadFile({
                    data: csv,
                    fileName: fileName,
                    fileType: 'text/csv'
                })

                //add audit trail in db
                // let role = `${isFinance() ? 'finance' : 'admin'}`
                // axios.post(`${process.env.REACT_APP_API_URL}/audits/add/exportedMonthlyReport`, { selectedMonth, selectedYear, user_id: user.id, role })

            })
            .catch(err => {
                alert(err.response.data)
            })
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }


    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        }}>
            {isFinance()
                &&
                <>
                    <Tooltip title="Upload CSV" placement="left">
                        <Button onClick={uploadEvent}>
                            <UploadIcon sx={{ color: "rgba(0, 0, 0, 0.7)" }} />
                        </Button>
                    </Tooltip>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className='upload-car-modal'
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Upload CSV/XLSX for Session {row.session_no}
                            </Typography>
                            <UploadFileForm session_id={row.id} />
                        </Box>
                    </Modal>
                </>
            }

            <Tooltip title="View Cars" placement="left">
                <Button onClick={viewEvent}>
                    <VisibilityIcon sx={{ color: "rgba(0, 0, 0, 0.7)" }} />
                </Button>
            </Tooltip>

            {!isFinance()
                &&
                <>
                    <Tooltip title="Email Car List to Dealers" placement="left">
                        <Button onClick={openCarListModal}>
                            {/* <ForwardToInboxIcon sx={{ color: "rgba(0, 0, 0, 0.7)" }} /> */}
                            <RiMailSendFill style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: '1.3rem' }} />
                        </Button>
                    </Tooltip>
                    
                    <Modal
                        open={openCarListEmailModal}
                        onClose={closeCarListModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className='car-list-email-modal'
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: '2rem' }}>
                                Send Car List Email for Session -  {row.session_no}
                            </Typography>
                            <textarea id='email-content-textarea' className='email-content-textarea' value={emailContent} onChange={e => setEmailContent(e.target.value)} />
                            <div style={{ padding: '0 0 2rem 0', overflowX: 'auto', marginTop: '1rem', overflowY: 'scroll' }}>
                                <table style={{ borderCollapse: 'collapse', borderRadius: '10px' }}>
                                    <thead style={{ fontSize: '0.9rem', fontWeight: '100' }}>
                                        <tr style={{ borderRadius: '5px', padding: '1rem', color: 'white', fontWeight: '100', backgroundColor: '#03424a' }}>
                                            <th style={{ borderRadius: '10px 0 0 0', padding: '1rem 1vw', fontWeight: 'normal' }}>S/N</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>IMAGE</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>CAR REGISTRATION</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>MODEL</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>COLOUR</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>YOM</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>REG. DATE</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>MILEAGE (km)</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>OMV</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>OWNERS</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>PAPER VALUE</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>ARF</th>
                                            <th style={{ padding: '1rem 1vw', fontWeight: 'normal' }}>REMARKS</th>
                                            <th style={{ borderRadius: '0 10px 0 0', padding: '1rem 1vw', fontWeight: 'normal' }}>ROAD TAX EXPIRY</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {carsDetails.length > 0
                                            ?
                                            carsDetails.map((car, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>{i + 1}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}><img style={{ width: '300px' }} src={car.src} />
                                                        </td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>{car.car_reg}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>{car.model}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>{car.colour}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>{car.yom}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>
                                                            {car.regn_date}
                                                        </td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>{car.mileage}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1cw' }}>${thousandSeperator(car.omv)}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>{car.owners}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>${thousandSeperator(car.papervalue)}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>${thousandSeperator(car.arf)}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>{car.remarks}</td>
                                                        <td style={{ border: '1px solid gray', padding: '1vw' }}>{car.roadtax_expiry}</td>
                                                    </tr>
                                                )
                                            })


                                            :
                                            <tr>
                                                <td colspan="14" style={{ border: '1px solid gray', padding: '5vw', textAlign: 'center' }}>No Cars</td>
                                            </tr>
                                        }

                                        <tr style={{ fontWeight: 'bold' }}>
                                            <td colSpan="14" style={{ border: '1px solid gray', padding: '1vw', textAlign: 'center' }}>T & C as per email</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="footer">
                                <div style={{ marginTop: '2rem' }}>
                                    {/* <button className="btn danger-btn">Cancel</button> */}
                                    <button className="btn primary-btn" onClick={emailCarListEvent} disabled={sendingEmail || emailSent}>
                                        {sendingEmail
                                            ? 'Sending...'
                                            :
                                            <>
                                                {
                                                    !emailSent
                                                        ?
                                                        <>
                                                            {row.car_list_email_sent === 1
                                                                ? 'Send Again'
                                                                : 'Yes, Send'}
                                                        </>
                                                        : <><DoneIcon sx={{ color: green[500] }} /> Sent</>
                                                }
                                            </>
                                        }
                                    </button>

                                </div>
                                <p>An email will be sent to all dealer notifying them about the cars in the session. Do you want to sent email to dealers about these cars?</p>

                                {row.car_list_email_sent === 1
                                    &&
                                    <p>NOTE: Car lists have already been emailed to dealers for this Session</p>
                                }
                            </div>
                        </Box>
                    </Modal>
                </>
            }


            <>
                <Tooltip title="View all bids in session" placement="right">
                    <Button onClick={() => navigate(`/allBids/${row.session_no}`)}>
                        {/* <EmojiEventsIcon sx={{ color: "rgba(0, 0, 0, 0.7)" }} /> */}
                        <RiAuctionFill style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: '1.3rem' }} />
                    </Button>
                </Tooltip>
            </>

            {isFinance()
                &&
                <>
                    <Tooltip title={`Export Session ${row.session_no} Report`} placement="right">
                        <Button onClick={() => exportSessionReport(row.session_no)}>
                            {/* <EmojiEventsIcon sx={{ color: "rgba(0, 0, 0, 0.7)" }} /> */}
                            <FaFileExport style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: '1.3rem' }} />
                        </Button>
                    </Tooltip>
                </>
            }

        </Box>
    );
}