import { Backdrop, Box, Button, Container, CssBaseline, Fade, Grid, Modal, Paper, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AdminPage, DataTable } from '../components'
import { getUser, isFinance } from "../utils/AuthSession"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Parser } from 'json2csv'
import { formatDateTime, thousandSeperator } from '../utils/Format'
import { IoClose } from 'react-icons/io5'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'

const AllBidsInSession = () => {
    const { sessionNo } = useParams()
    const [allBids, setAllBids] = useState([]);
    const [selectedBidId, setSelectedBidId] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    //Modal
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        p: 4,
    };

    const showTooltip = (params) => {
        if (params.row[params.field]) {
            return (
                <Tooltip title={params.row[params.field]} placement="top">
                    <label>
                        {params.row[params.field]}
                    </label>
                </Tooltip>
            );
        }
    }

    const formatDate = (value) => {
        //DD/MM/YYYY hh:mm a
        let date = new Date(String(value));
        let year = date.getFullYear(),
            month = (date.getUTCMonth() + 1),
            day = date.getUTCDate(),
            hour = date.getHours(),
            min = date.getMinutes();
        let period = "AM";
        if (Number(hour) >= 12) {
            hour -= 12;
            period = "PM";
        }
        if (String(month).length === 1) {
            month = "0" + month;
        }
        if (String(day).length === 1) {
            day = "0" + day;
        }
        if (String(hour).length === 1) {
            hour = "0" + hour;
        }
        if (String(min).length === 1) {
            min = "0" + min;
        }
        // let new_date = day + "/" + month + "/" + year + " " + hour + ":" + min + " " + period;
        // return new_date;
        return `${day}-${month}-${year} ${hour}:${min}`
    }
    // const renderCell = { renderCell: showTooltip };
    // const columns = [
    //     {
    //         field: 'id', headerName: 'ID',
    //         renderCell: (params) => {
    //             return params.row['id'];
    //         }
    //     },
    //     {
    //         field: 'remark',
    //         headerName: 'Cancel Bid',
    //         renderCell: (params) => {
    //             if (!isFinance()) {
    //                 if (params.row.accepted === 1) {
    //                     return <Tooltip title="Can't cancel accepted bids" placement="left">
    //                         <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
    //                             -
    //                         </Box>
    //                     </Tooltip>
    //                 } else {
    //                     if (params.row['active'] === 1) {
    //                         return <Tooltip title="Cancel Bid" placement="left">
    //                             <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
    //                                 <button value={params.row['id']} onClick={(e) => { setSelectedBidId(e.target.value); handleOpen() }} className='cancel-btn' ><IoClose className='cancel-icon' /></button>
    //                             </Box>
    //                         </Tooltip>
    //                     } else {
    //                         return <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
    //                             <p>Cancelled</p>
    //                         </Box>
    //                     }
    //                 }
    //             } else {
    //                 return <Tooltip title="Unauthorized" placement="left">
    //                     <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
    //                         -
    //                     </Box>
    //                 </Tooltip>
    //             }
    //         },
    //     },
    //     // { field: 'car_id', headerName: 'car_id', },
    //     // { field: 'bidsession_id', headerName: 'bidsession_id', },
    //     { field: 'Username', headerName: 'Dealer', },
    //     { field: 'Company Name', headerName: 'Company Name', },
    //     { field: 'Email', headerName: 'Email', width: 180 },
    //     { field: 'Model', headerName: 'Model', },
    //     { field: 'Regn No', headerName: 'Regn No', },
    //     // { field: 'colour', headerName: 'colour', },
    //     // { field: 'yom', headerName: 'yom', },
    //     // { field: 'regn_date', headerName: 'regn_date', },
    //     // { field: 'mileage', headerName: 'mileage', },
    //     // { field: 'omv', headerName: 'omv', },
    //     // { field: 'owners', headerName: 'owners', },
    //     // { field: 'papervalue', headerName: 'papervalue', },
    //     // { field: 'roadtax_expiry', headerName: 'roadtax_expiry', },
    //     // { field: 'remarks', headerName: 'remarks', },
    //     { field: 'Amount', headerName: 'Bid Amount', },
    //     { field: 'Bid Type', headerName: 'Bid Type', },
    //     { field: 'Bid Date-Time', headerName: 'Bid Date-Time', },
    //     { field: 'Accepted', headerName: 'Bid Status', },
    //     // { field: 'minbidvalue_wholecar', headerName: 'Reserved Price Wholecar', },
    //     // { field: 'minbidvalue_bodyonly', headerName: 'Reserved Price Bodyonly', },
    // ];

    let columns
    if (isFinance()) {
        columns = [
            { dataField: 'id', text: 'ID' },
            { dataField: 'Username', text: 'Dealer', sort: true, filter: textFilter() },
            { dataField: 'Company Name', text: 'Company Name', sort: true, filter: textFilter() },
            // { dataField: 'Email', text: 'Email', sort: true },
            { dataField: 'Model', text: 'Model', sort: true, filter: textFilter() },
            { dataField: 'Regn No', text: 'Regn No', sort: true, filter: textFilter() },
            { dataField: 'Amount', text: 'Bid Amount', sort: true },
            { dataField: 'Bid Type', text: 'Bid Type', sort: true },
            {
                dataField: 'Bid Date-Time', text: 'Bid Date-Time', sort: true,
                formatter: (rowContent, row) => {
                    return `${formatDate(row['Bid Date-Time'])}`
                }
            },
            { dataField: 'Bid Status', text: 'Bid Status', sort: true },
            // { dataField: 'Accepted', text: 'Accepted', sort: true },
        ]
    } else {
        columns = [
            { dataField: 'id', text: 'ID' },
            {
                dataField: 'Active', text: 'Cancel Bid',
                formatter: (rowContent, row) => {
                    if (row.accepted === 1) {
                        return <Tooltip title="Can't cancel accepted bids" placement="left">
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                -
                            </Box>
                        </Tooltip>
                    } else {
                        if (row['active'] === 1) {
                            return <Tooltip title="Cancel Bid" placement="left">
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                    <button value={row['id']} onClick={(e) => { setSelectedBidId(e.target.value); handleOpen() }} className='cancel-btn' ><IoClose className='cancel-icon' /></button>
                                </Box>
                            </Tooltip>
                        } else {
                            return <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                <p>Cancelled</p>
                            </Box>
                        }
                    }
                }
            },
            { dataField: 'Username', text: 'Dealer', sort: true, filter: textFilter() },
            { dataField: 'Company Name', text: 'Company Name', sort: true, filter: textFilter() },
            // { dataField: 'Email', text: 'Email', sort: true },
            { dataField: 'Model', text: 'Model', sort: true, filter: textFilter() },
            { dataField: 'Regn No', text: 'Regn No', sort: true, filter: textFilter() },
            {
                dataField: 'Amount', text: 'Bid Amount', sort: true,
                formatter: (rowContent, row) => {
                    return `$${thousandSeperator(row['Amount'])}`
                }
            },
            { dataField: 'Bid Type', text: 'Bid Type', sort: true },
            {
                dataField: 'Bid Date-Time', text: 'Bid Date-Time', sort: true,
                formatter: (rowContent, row) => {
                    return `${formatDate(row['Bid Date-Time'])}`
                }
            },
            { dataField: 'Bid Status', text: 'Bid Status', sort: true },
            // { dataField: 'Accepted', text: 'Accepted', sort: true },
        ]
    }

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
    })

    const cancelBid = (bid_id) => {
        axios.post(`${process.env.REACT_APP_API_URL}/admins/cancelBid`, { bid_id, username: getUser().username, user_id: getUser().id })
            .then(res => {
                if (res.data) {
                    alert(res.data)
                    handleClose()
                    setRefresh(!refresh)
                }
            })
            .catch(err => {
                alert(err.response.data)
                handleClose()
            })
    }



    useEffect(() => {
        loadAllBidsInSession()
    }, [sessionNo, refresh])

    const loadAllBidsInSession = () => {
        setLoading(true)
        const user = getUser();
        axios.post(`${process.env.REACT_APP_API_URL}/admins/allBids/${sessionNo}`, { user_id: user.id })
            .then(res => {
                // console.log(res.data)
                setAllBids(res.data)
                setLoading(false)
            })
            .catch(err => {
                alert(err.response.data)
                setLoading(false)
            })
    }


    const exportBids = () => {
        //create csv file of the data
        // const fileName = `MonthlyReport ${['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][selectedMonth - 1]} ${selectedYear}`
        let fields = ['id', 'Username', 'Company Name', 'Email', 'Model', 'Regn No', 'Amount', 'Bid Type', 'Bid Date-Time', 'Accepted', 'Bid Status'];
        const opts = { fields };

        const parser = new Parser(opts);
        const csv = parser.parse(allBids);

        downloadFile({
            data: csv,
            fileName: `Session ${sessionNo} Report`,
            fileType: 'text/csv'
        })
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const emptyDataMessage = () => { return <div style={{ fontSize: '15px' }}>No rows</div> }

    return (
        <AdminPage>
            <Container maxWidth="lg">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    <div className="flex">
                        <button className="back-btn" onClick={() => navigate(-1)}>
                            <ArrowBackIcon /> Back
                        </button>
                        <Typography component="h1" variant="h5">
                            Session {sessionNo} - All Bids
                        </Typography>
                    </div>

                    <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box sx={{ p: 2, pl: 5, pr: 5, display: 'flex', flexDirection: 'column' }}>
                                    <div className="header-flexed">
                                        <div />
                                        <div className="header-right-aligned">
                                            <Tooltip
                                                title="Export all bids in all sessions of selected month"
                                                placement="top"
                                            >
                                                <button className="btn light-btn btn-pill" onClick={() => exportBids()}>Export</button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {/* <DataTable
                                        className='all-bids-data-table'
                                        loading={loading}
                                        rows={allBids}
                                        columns={columns}
                                        rowHeight={50}
                                        components={{ Toolbar: GridToolbar }}
                                        pageSize={100} rowsPerPageOptions={[10]}
                                        disableColumnFilter
                                        disableExportButton
                                        disableColumnSelector
                                        disableDensitySelector
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                    /> */}

                                    {/* <table>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Cancel Bid</th>
                                                <th>Dealer</th>
                                                <th>Company Name</th>
                                                <th>Email</th>
                                                <th>Model</th>
                                                <th>Regn No</th>
                                                <th>Bid Amount</th>
                                                <th>Bid Type</th>
                                                <th>Bid Date-Time</th>
                                                <th>Bid Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading
                                                ? <tr><td colSpan='11'>Loading...</td></tr>

                                                : <>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td><input type='text'/></td>
                                                    <td><input type='text'/></td>
                                                    <td><input type='text'/></td>
                                                    <td><input type='text'/></td>
                                                    <td><input type='text'/></td>
                                                    <td><input type='text'/></td>
                                                </tr>
                                                    {
                                                        allBids.length === 0
                                                            ? <tr><td colSpan='11'>No rows to show</td></tr>
                                                            : <>
                                                                {allBids.map((bid, i) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{i + 1}</td>
                                                                            <td>{
                                                                                bid.accepted === 1
                                                                                    ? <Tooltip title="Can't cancel accepted bids" placement="left">
                                                                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                                                                            -
                                                                                        </Box>
                                                                                    </Tooltip>

                                                                                    : <>
                                                                                        {
                                                                                            bid.active === 1
                                                                                                ? <Tooltip title="Cancel Bid" placement="left">
                                                                                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                                                                                        <button value={bid.id} onClick={(e) => { setSelectedBidId(e.target.value); handleOpen() }} className='cancel-btn' ><IoClose className='cancel-icon' /></button>
                                                                                                    </Box>
                                                                                                </Tooltip>

                                                                                                : <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                                                                                    <p>Cancelled</p>
                                                                                                </Box>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                            </td>

                                                                            <td>{bid['Username']}</td>
                                                                            <td>{bid['Company Name']}</td>
                                                                            <td>{bid['Email']}</td>
                                                                            <td>{bid['Model']}</td>
                                                                            <td>{bid['Regn No']}</td>
                                                                            <td>{bid['Amount']}</td>
                                                                            <td>{bid['Bid Type']}</td>
                                                                            <td>{formatDateTime(bid['Bid Date-Time'])}</td>
                                                                            <td>{bid['Accepted']}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </>
                                                    }
                                                </>
                                            }
                                        </tbody>
                                    </table> */}

                                    <div className='table-container'>

                                        <BootstrapTable
                                            classes='all-bids-table'
                                            bootstrap4
                                            keyField='id'
                                            data={allBids}
                                            columns={columns}
                                            filter={filterFactory()}
                                            noDataIndication={emptyDataMessage}
                                        // pagination
                                        />
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>


                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    className='confirmation-modal'
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <h3>Confirmation</h3>
                            <hr className='hr'></hr>
                            <h4>Are you sure you want to cancel this bid?</h4>
                            {/* <p>Note: Bid once cancelled cannot be ativated again.</p> */}

                            <button className='btn danger-btn' onClick={() => cancelBid(selectedBidId)}>Cancel Anyway</button>
                        </Box>
                    </Fade>
                </Modal>
            </Container>
        </AdminPage >

    )
}

export default AllBidsInSession
